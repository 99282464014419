/**
 * 标签栏数据缓存处理
 */
import clientCache from '@/store/utils/client-cache'
import systemConfig from '@/config/system-config'
import authorization from '@/store/utils/authorization'

const tagsBarStore = {
  state: {
    // 访问过的标签
    visitedTagList: [],
    // 默认显示的标签（没有关闭按钮的标签）
    defaultTag: null
  },
  mutations: {
    SET_TAGS: (state, tags) => {
      state.visitedTagList = tags
      clientCache.sessionCache.setJSON('TAGS', tags)
    },
    SET_DEFAULT_TAG: (state, defaultTag) => {
      state.defaultTag = defaultTag
      clientCache.sessionCache.setJSON('DEFAULT_TAG', defaultTag)
    }
  },
  getters: {
    getDefaultTag(state) {
      if (systemConfig.CACHE_CRYPTO) {
        if (state.defaultTag) {
          return state.defaultTag
        } else if (clientCache.sessionCache.getJSON('DEFAULT_TAG')) {
          return clientCache.sessionCache.getJSON('DEFAULT_TAG')
        } else {
          // 由于登录成功后缓存了数据到sessionStorage,不可能获取不到数据，除非数据损坏
          console.error('tags缓存数据已损坏！')
          authorization.handleLogout()
        }
      } else {
        return state.defaultTag || clientCache.sessionCache.getJSON('DEFAULT_TAG')
      }
    },
    getVisitedTagList(state) {
      if (systemConfig.CACHE_CRYPTO) {
        if (JSON.stringify(state.visitedTagList) !== '[]') {
          return state.visitedTagList
        } else if (clientCache.sessionCache.getJSON('TAGS')) {
          return clientCache.sessionCache.getJSON('TAGS')
        } else {
          // 由于登录成功后缓存了数据到sessionStorage,不可能获取不到数据，除非数据损坏
          console.error('tags缓存数据已损坏！')
          authorization.handleLogout()
        }
      } else {
        return JSON.stringify(state.visitedTagList) === '[]' ? clientCache.sessionCache.getJSON('TAGS') : state.visitedTagList
      }
    }
  }
}
export default tagsBarStore
