import Vue from 'vue'
import Vuex from 'vuex'
import requestStore from '@/store/modules/request-store'
import loginStore from '@/store/modules/login-store'
import tagsBarStore from '@/store/modules/tag-bar-store'
import trackerStore from '@/store/modules/tracker-store'
import clientStore from '@/store/modules/client-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    requestStore,
    loginStore,
    tagsBarStore,
    trackerStore,
    clientStore
  }
})
