import axios from 'axios'
import systemConfig from '@/config/system-config'
import utilKit from '@/util'

const serXhr = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/devApi' : process.env.VUE_APP_BASE_URL,
  // 超时时间
  timeout: systemConfig.REQUEST_TIMEOUT
})

serXhr.interceptors.request.use((config) => {
  // 将axios请求交由统一请求拦截器处理后返回
  return utilKit.unifiedReqInterceptor.doInterceptor(config)
}, (error) => {
  // axios请求异常时抛出
  return Promise.reject(error)
})

serXhr.interceptors.response.use((response) => {
  // 将axios响应交由统一响应拦截器处理
  return utilKit.unifiedResInterceptor.doInterceptor(response)
}, error => {
  // 将axios响应异常交由统一响应拦截器处理
  return utilKit.unifiedResInterceptor.resErrorHandler(error).catch(e => {
    console.log(e)
  })
})

export { serXhr }
