import systemConfig from '@/config/system-config'
import clientCache from '@/store/utils/client-cache'
import utilKit from '@/util'

const doInterceptor = function(axiosRequestConfig) {
  if (systemConfig.RESUBMIT_INTERCEPT && systemConfig.RESUBMIT_INTERCEPT_METHODS.indexOf(axiosRequestConfig.method) !== -1) {
    const url = axiosRequestConfig.url
    // 本次请求数据对象
    const requestObj = {
      url: url,
      data: typeof axiosRequestConfig.data === 'object' ? JSON.stringify(axiosRequestConfig.data) : axiosRequestConfig.data,
      params: typeof axiosRequestConfig.params === 'object' ? JSON.stringify(axiosRequestConfig.params) : axiosRequestConfig.params
    }

    // 本次会话信息
    const sessionInfo = {
      time: new Date().getTime(),
      requestObj: utilKit.md5Crypt.Encrypt(JSON.stringify(requestObj))
    }

    // 读取缓存中的会话信息
    const sessionInfoFromCache = clientCache.sessionCache.getJSON('sessionInfo')

    // 检测会话信息是否重复
    if (sessionInfoFromCache === undefined || sessionInfoFromCache === null || sessionInfoFromCache === '') {
      clientCache.sessionCache.setJSON('sessionInfo', sessionInfo)
      return true
    } else {
      const requestObj = sessionInfoFromCache.requestObj // 请求数据
      const time = sessionInfoFromCache.time // 请求时间
      const interval = systemConfig.RESUBMIT_INTERCEPT_INTERVAL

      if (requestObj === sessionInfo.requestObj && sessionInfo.time - time <= interval) {
        console.warn(`[duplicateReqInterceptor]已拦截重复请求：${url}`)
        return false
      } else {
        clientCache.sessionCache.setJSON('sessionInfo', sessionInfo)
        return true
      }
    }
  } else {
    return true
  }
}

export default { doInterceptor }
