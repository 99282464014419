/**
 * 埋点数据操作接口
 */
import axiosApi from '@/service/modules/axios-api'
import { TRACK_MICRO_SERVICE_PREFIX } from '@/service'
export default {
  sendTrackData (data) {
    return axiosApi.post(TRACK_MICRO_SERVICE_PREFIX + '/track/send', data, { isAuth: false })
  }
}
