import crypto from '@/security/modules/crypto'
import utilKit from '@/util'
import systemConfig from '@/config/system-config'
// 装配会话重放防御
const assembleDefense = function (aimObj, saltFlag) {
  if (utilKit.checkDataType.getType(aimObj) === 'Object') {
    if (saltFlag) {
      aimObj.sessionStamp = systemConfig.REPLAY_ATTACKS_SALT + crypto.completeEncrypt(JSON.stringify((new Date()).getTime()))
    } else {
      aimObj.sessionStamp = crypto.completeEncrypt(JSON.stringify((new Date()).getTime()))
    }
    return aimObj
  } else {
    throw new Error('只能给Object装配会话重放防御。Expected input parameter is of type \'Object\', but got a \'' + utilKit.checkDataType.getType(aimObj) + '\'.')
  }
}

// 卸载会话重放防御
const unloadDefense = function (aimObj) {
  if (utilKit.checkDataType.getType(aimObj) === 'Object') {
    if (aimObj.sessionStamp) {
      delete aimObj.sessionStamp
    }
    return true
  } else {
    throw new Error('只能给Object卸载会话重放防御。Expected input parameter is of type \'Object\', but got a \'' + utilKit.checkDataType.getType(aimObj) + '\'.')
  }
}
export default { assembleDefense, unloadDefense }
