import systemConfig from '@/config/system-config'
import securityKit from '@/security'

export default {
  /**
   * 会话级缓存(对应sessionStorage)
   */
  sessionCache: {
    set (key, value) {
      if (!sessionStorage) {
        return
      }
      if (key && value) {
        if (systemConfig.CACHE_CRYPTO) {
          sessionStorage.setItem(key, securityKit.cacheCrypto.completeEncrypt(value))
        } else {
          sessionStorage.setItem(key, value)
        }
      }
    },
    get (key) {
      if (!sessionStorage || !key) {
        return null
      }
      try {
        if (systemConfig.CACHE_CRYPTO) {
          return sessionStorage.getItem(key) ? securityKit.cacheCrypto.completeDecrypt(sessionStorage.getItem(key)) : null
        } else {
          return sessionStorage.getItem(key)
        }
      } catch (e) {
        console.error('会话缓存数据损坏，读取失败：', e)
        return null
      }
    },
    setJSON (key, jsonValue) {
      if (jsonValue) {
        this.set(key, JSON.stringify(jsonValue))
      }
    },
    getJSON (key) {
      const value = this.get(key)
      if (value) {
        try {
          if (value) {
            return JSON.parse(value)
          }
          return null
        } catch (e) {
          console.error('会话缓存数据损坏，读取失败：', e)
          return null
        }
      }
    },
    remove (key) {
      sessionStorage.removeItem(key)
    },
    clearAll () {
      sessionStorage.clear()
    }
  },

  /**
   * 本地缓存(对应localStorage)
   */
  localCache: {
    set (key, value) {
      if (!localStorage) {
        return
      }
      if (key && value) {
        if (systemConfig.CACHE_CRYPTO) {
          localStorage.setItem(key, securityKit.cacheCrypto.completeEncrypt(value))
        } else {
          localStorage.setItem(key, value)
        }
      }
    },
    get (key) {
      if (!localStorage || !key) {
        return null
      }
      try {
        if (systemConfig.CACHE_CRYPTO) {
          return localStorage.getItem(key) ? securityKit.cacheCrypto.completeDecrypt(localStorage.getItem(key)) : null
        } else {
          return localStorage.getItem(key)
        }
      } catch (e) {
        console.error('本地缓存数据损坏，读取失败：', e)
        return null
      }
    },
    setJSON (key, jsonValue) {
      if (jsonValue) {
        this.set(key, JSON.stringify(jsonValue))
      }
    },
    getJSON (key) {
      const value = this.get(key)
      try {
        if (value) {
          return JSON.parse(value)
        }
        return null
      } catch (e) {
        console.error('本地缓存数据损坏，读取失败：', e)
        return null
      }
    },
    remove (key) {
      localStorage.removeItem(key)
    },
    clearAll () {
      localStorage.clear()
    }
  }
}
