import store from '@/store'
import systemConfig from '@/config/system-config'
let clickFlag = true
const tracker = function(el, binding) {
    if (binding.value && systemConfig.EVENT_TRACKING) {
      const params = {
        currentUrl: binding.value.currentUrl,
        actionType: binding.value.actionType,
        frontTriggerType: binding.value.triggerType,
        behavior: binding.value.behavior
      }
      // 如果是浏览类型，直接保存
      if (binding.value.triggerType === 'browse') {
        // 调用后台接口保存数据
        console.log('【浏览埋点】', params)
        store.commit('ADD_TRACK', params)
      } else if (binding.value.triggerType === 'click') {
        // 监听click事件
        el.addEventListener('click', (event) => {
          // 调用后台接口保存数据
          // 限流
          if (clickFlag) {
            clickFlag = false
            console.log('【点击埋点】', params)
            store.commit('ADD_TRACK', params)
            setTimeout(() => {
              clickFlag = true
            }, 3000)
          }
        }, false)
      }
    }
  }

export default tracker
