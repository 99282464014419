/**
 * 埋点数据暂存
 */
import clientCache from '@/store/utils/client-cache'
const trackerStore = {
  state: {
    // 埋点缓存数据
    trackerList: []
  },
  mutations: {
    ADD_TRACK: (state, track) => {
      state.trackerList = state.trackerList.concat([track])
      clientCache.localCache.setJSON('TRACK_LIST', state.trackerList)
    },
    SET_TRACK: (state, track) => {
      state.trackerList = track
      clientCache.localCache.setJSON('TRACK_LIST', state.trackerList)
    }
  },
  getters: {
    getTrackerList(state) {
      return JSON.stringify(state.trackerList) === '[]' ? clientCache.localCache.getJSON('TRACK_LIST') : state.trackerList
    }
  }
}
export default trackerStore
