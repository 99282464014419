import securityKit from '@/security'
/**
 * axios请求参数加密
 * axiosRequestConfig:axios配置
 * encryptType: 加密类型（完整加密（’complete‘）或标准加密（’standard‘））
 */
const axiosReqEncrypt = function (axiosRequestConfig, encryptType = 'standard') {
  const reqObj = axiosRequestConfig.params || axiosRequestConfig.data || null
  if (reqObj && encryptType) {
    if (encryptType === 'standard') {
      if (axiosRequestConfig.params) {
        axiosRequestConfig.params = securityKit.smCrypto.standardEncrypt(reqObj)
      } else {
        axiosRequestConfig.data = securityKit.smCrypto.standardEncrypt(reqObj)
      }
    } else {
      if (axiosRequestConfig.params) {
        axiosRequestConfig.params = securityKit.smCrypto.completeEncrypt(reqObj)
      } else {
        axiosRequestConfig.data = securityKit.smCrypto.completeEncrypt(reqObj)
      }
    }
    return axiosRequestConfig
  }
  return axiosRequestConfig
}

/**
 * axios响应数据解密
 * axiosRequestConfig:axios配置
 * encryptType: 解密类型（完整解密（’complete‘）或标准解密（’standard‘））
 */
const axiosResDecrypt = function (axiosResponse, decryptType = 'standard') {
  const resObj = axiosResponse.data || null
  if (resObj && decryptType) {
    if (decryptType === 'standard') {
      axiosResponse.data = securityKit.smCrypto.standardDecrypt(resObj)
    } else {
      axiosResponse.data = securityKit.smCrypto.completeDecrypt(resObj)
    }
    return axiosResponse
  }
  return axiosResponse
}

export default { axiosReqEncrypt, axiosResDecrypt }
