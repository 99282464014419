/**
 * ISC微服务请求前缀配置
 */
export const ISC_MICRO_SERVICE_PREFIX = '/tg-system'
/**
 * 登录微服务请求前缀配置
 */
export const LOGIN_MICRO_SERVICE_PREFIX = ''
/**
 * 主业务微服务请求前系缀配置
 */
export const MAIN_MICRO_SERVICE_PREFIX = ''
/**
 * 系统管理微服务请求前缀配置
 */
export const SYS_MICRO_SERVICE_PREFIX = '/tg-system'
/**
 * 埋点微服务请求前缀配置
 */
export const TRACK_MICRO_SERVICE_PREFIX = '/tg-system'
/**
 * 字典管理微服务请求前缀配置
 */
export const DICT_MANAGE_SERVICE_PREFIX = '/tg-system'
/**
 * 审计管理微服务请求前缀配置
 */
export const AUDIT_SERVICE_PREFIX = '/tg-log'
