/**
 * v-checkRole指令：校验角色
 * 校验当前用户是否具有相应角色，控制DOM元素显示或隐藏
 */
import authorization from '@/store/utils/authorization'

const inserted = function (el, binding, vnode) {
  const { value } = binding
  const superAdmin = 'admin'
  let roles = []
  if (authorization.getUserInfo() && authorization.getUserInfo().roles) {
    roles = authorization.getUserInfo().roles
  }

  if (value && value instanceof Array && value.length > 0) {
    const roleFlag = value

    const hasRole = roles.some(role => {
      return superAdmin === role || roleFlag.includes(role)
    })

    if (!hasRole) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error('请设置角色权限标签值"')
  }
}
export default inserted
