<template>
  <div id="app">
      <router-view/>
  </div>
</template>
<script>
import authorization from '@/store/utils/authorization'
import trackApi from '@/service/api/track/track-api'
import clientCache from '@/store/utils/client-cache'
import systemConfig from '@/config/system-config'
import utilKit from '@/util'
import store from '@/store'
export default {
  data() {
    return {
      trackListener: null
    }
  },
  beforeUpdate() {
    // 页面行为处理
    if (systemConfig.EVENT_TRACKING) {
      this.handlePageAction()
    }
  },
  mounted() {
    // 启动埋点数据发送定时器
    if (!this.trackListener) {
      this.trackListener = window.setInterval(this.handleTrack, 30000)
    }
  },
  methods: {
    // 处理页面行为（仅在App.vue的beforeUpdate()钩子函数中使用）
    handlePageAction() {
      // 获取页面行为
      const pageAction = utilKit.checkClient.clientAction()

      // 新开会话窗口，则清空本地缓存
      if (pageAction === 'new') {
        console.log('检测到新开客户端！')
        // 若新开会话，则清空本地缓存
        clientCache.localCache.clearAll()
      }

      // 页面切换
      if (pageAction === 'shift') {
        console.log('检测到页面切换！')
      }

      // 页面刷新，则将已有的埋点缓存数据全部发送到服务器
      if (pageAction === 'refresh') {
        console.log('检测到页面刷新！')
        if (this.$store.getters.getTrackerList) {
          // 获取埋点缓存数据
          const tempCache = this.$store.getters.getTrackerList
          const trackInfo = { triggerType: 'browse', currentUrl: this.$route.fullPath, behavior: `刷新${this.$route.meta.menuName}页面`, actionType: '刷新页面' }
          // 追加埋点数据
          tempCache.push(trackInfo)
          // 刷新埋点数据
          store.commit('SET_TRACK', tempCache)
          // 请求远程存储
          this.handleTrack()
        }
      }
    },
    handleTrack() {
      if (this.$store.getters.getTrackerList && this.$store.getters.getTrackerList.length > 0) {
        trackApi.sendTrackData({ trackData: this.$store.getters.getTrackerList }).catch(err => {
          console.error(err)
        }).finally(() => {
          // 发送结束清空缓存
          this.$store.commit('SET_TRACK', [])
        })
        // 发送结束清空缓存(考虑到网络延迟，不管是否请求成功，都清空缓存)
        this.$store.commit('SET_TRACK', [])
      }
    }
  },
  beforeDestroy () {
    // 移除定时器
    if (this.trackListener) {
      window.clearInterval(this.trackListener)
    }
    // 清空本地记录
    authorization.handleLogout()
  }
}
</script>

<style lang="scss">
html,body{
  padding: 0;
  margin: 0;
  font-family: SiYuan-Regular, PingFangSC-Regular, Avenir, sans-serif;
}
#app{
  min-width: 768px;
}
</style>
