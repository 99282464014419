/*    axios-api.js    */
/*    对主业务XHR进行封装    */
import { serXhr } from '@/service/modules/ser-xhr'

const axiosApi = {
  /**
   * @param url 请求路径
   * @param params get请求参数列表
   * @param config 接受三个字段：isAuth[String]（是否追加权限请求头）、menuId[String]（自定义的页面id）、headers[Object]（自定义请求头）;
   * @returns {AxiosPromise}
   */
  get (url, params, config = {}) {
    const CONFIG = {
      method: 'get',
      url: url,
      params: params,
      headers: config.header || {}
    }
    if (config.isAuth === false) CONFIG.headers.isAuth = false
    if (config.menuId) CONFIG.menuId = config.menuId
    return serXhr(CONFIG)
  },

  /**
   * @param url 请求路径
   * @param data post请求参数列表
   * @param config 接受三个字段：isAuth[String]（是否追加权限请求头）、menuId[String]（自定义的页面id）、headers[Object]（自定义请求头）;
   * @returns {AxiosPromise}
   */
  post (url, data, config = {}) {
    const CONFIG = {
      method: 'post',
      url: url,
      data: data,
      headers: config.header || {}
    }
    if (config.isAuth === false) CONFIG.headers.isAuth = false
    if (config.menuId) CONFIG.menuId = config.menuId
    return serXhr(CONFIG)
  },

  /**
   * 导出、下载专用请求方法
   * @param url 请求路径
   * @param params get请求参数列表
   * @param config 接受三个字段：isAuth[String]（是否追加权限请求头）、menuId[String]（自定义的页面id）、headers[Object]（自定义请求头）;
   * @returns {AxiosPromise}
   */
  getExport (url, params, config = {}) {
    const CONFIG = {
      method: 'get',
      url: url,
      params: config.params,
      responseType: 'arraybuffer',
      headers: config.header || {}
    }
    if (config.isAuth === false) CONFIG.headers.isAuth = false
    if (config.menuId) CONFIG.menuId = config.menuId
    return serXhr(CONFIG)
  }
}

export default axiosApi
