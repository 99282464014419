import utilKit from '@/util'
import securityKit from '@/security'
import authorization from '@/store/utils/authorization'
import axios from 'axios'
import store from '@/store'
import systemConfig from '@/config/system-config'

/**
 * 统一请求拦截器
 */
const doInterceptor = function (axiosRequestConfig) {
  let config = axiosRequestConfig
  // 拦截重复请求
  if (!utilKit.duplicateReqInterceptor.doInterceptor(axiosRequestConfig)) {
    // 抛出重复请求异常标识，便于在响应异常处理中捕获处理
    return Promise.reject(new Error('DUPLICATE_REQ'))
  }

  // 请求参数序列化
  config = utilKit.reqFormatTool.reqFormat(axiosRequestConfig)

  // 加密
  if (systemConfig.REQ_CRYPTO_FLAG) {
    config.headers.encrypt = 1
    config = securityKit.axiosCrypt.axiosReqEncrypt(axiosRequestConfig, 'standard')
  }

  // 默认会添加Token请求头(可为不需要添加Token请求头的请求设置isAuth请求头为false)
  if (!((axiosRequestConfig.headers || {}).isAuth === false)) {
    config.headers.Authorization = 'Bearer ' + authorization.getToken()
  }

  // 添加审计日志请求头
  if (systemConfig.LOG_ENABLE) {
    if (axiosRequestConfig.menuId) {
      config.headers.pageId = axiosRequestConfig.menuId
    }
    if (store.getters.getCurrentMenuId) {
      config.headers.pageId = store.getters.getCurrentMenuId
    }
  }

  // 请求存储到vuex(在页面切换时中断未完成的请求)
  config.cancelToken = new axios.CancelToken(c => {
    store.commit('addRequestList', c)
  })

  return config
}

export default { doInterceptor }
