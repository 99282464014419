/**
 * 统一鉴权处理（动态路由生成，登录、登出善后处理）
 */
import clientCache from '@/store/utils/client-cache'
import router from '@/router'
import store from '@/store'
import systemConfig from '@/config/system-config'
import { Notification } from 'element-ui'

function loadView (view) {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/${view}`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/${view}`)
  }
}

function filterAsyncRouter (resRouter) {
  return resRouter.filter(route => {
    route.component = loadView(route.component)
    if (route.id) {
      route.meta.menuId = route.id
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

const authorization = {
  // 处理登录
  handleLogin (data) {
    // Token
    if (!data.authToken) {
      Notification.error('认证异常！')
      throw new Error('登录响应体缺少authToken')
    }
    // 用户信息
    if (!data.userInfo || !data.userInfo.userId) {
      Notification.error('身份异常！')
      throw new Error('登录响应体缺少userInfo')
    }
    // 用户路由
    if (!data.userRouter || data.userRouter.length === 0) {
      Notification.error('暂无可访问的菜单！')
      throw new Error('登录响应体缺少userRouter')
    }

    // 存储Token
    store.commit('SET_TOKEN', data.authToken)
    // 存储用户路由
    store.commit('SET_USERINFO', data.userInfo)
    // 存储用户路由
    store.commit('SET_USER_ROUTER', data.userRouter)

    // 存储用户菜单
    const menuList = authorization.filterMenuList(data.userRouter)
    store.commit('SET_USER_MENU', menuList)
    // 计算用户可访问的第一个菜单
    authorization.calcFirstPath(menuList)

    // 跳转路径构建
    const toPath = systemConfig.ISC_ENABLE ? systemConfig.ISC_ACCESS_PATH : systemConfig.FIRST_PAGE_PATH || store.getters.getFirstMenuPath
    store.commit('setAutoJumpPath', toPath)
    authorization.autoJump()
  },

  // 自动跳转到用户可访问的第一个菜单（可用于登录后自动跳转、404页面”返回安全页面“按钮进行调用）
  autoJump() {
    const safePath = store.getters.getAutoJumpPath
    if (!safePath) {
      console.log('自动跳转路径数据损坏')
      window.location.href = '/login'
    } else {
      window.location.href = safePath
    }
  },

  // 登出
  handleLogout () {
    store.commit('SET_TOKEN', '')
    store.commit('SET_USERINFO', '')
    store.commit('SET_USER_ROUTER', '')
    authorization.clearAllCache()
    router.push('/logout').catch(() => {
      return true
    })
  },

  // 更新token
  setToken (tokenStr) {
    if (tokenStr) {
      clientCache.localCache.set('AUTH_TOKEN', tokenStr)
    }
  },

  // 获取token
  getToken () {
    return clientCache.localCache.get('AUTH_TOKEN')
  },

  // 清空所有本地存储
  clearAllCache () {
    clientCache.localCache.clearAll()
    clientCache.sessionCache.clearAll()
  },

  // 初始化用户路由(入参是后端返回的原始路由)
  initializeUserRouter (responseRouter) {
    // 本工程所有后台管理页面均在App.vue下的Home.vue组件中显示
    const rootRouter = {
      path: '/home',
      name: 'Home',
      meta: {
        menuCode: 'Home_page',
        menuName: '首页'
      },
      component: 'views/Home',
      children: []
    }
    return new Promise(resolve => {
      rootRouter.children = JSON.parse(JSON.stringify(responseRouter))
      const rdata = filterAsyncRouter([rootRouter])
      rdata.push({ path: '*', redirect: '/404' })
      for (let i = 0, length = rdata.length; i < length; i += 1) {
        const element = rdata[i]
        router.addRoute(element)
      }
      store.commit('SET_USER_ROUTER', responseRouter)
      resolve(rdata)
    })
  },

  // 计算用户菜单
  filterMenuList(userRouter) {
    const originMenu = JSON.parse(JSON.stringify(userRouter))
    return authorization.madeMenu(originMenu)
  },

  // 计算用户可访问的第一个菜单
  calcFirstPath(menuList) {
    const originMenu = JSON.parse(JSON.stringify(menuList))
    const firstMenu = authorization.getFirstLeafMenu(originMenu)
    // 存储用户可访问的第一个菜单路径
    store.commit('SET_FIRST_MENU_PAGE', firstMenu.path)
    // 标签栏信息初始化
    authorization.initTagsInfo(firstMenu)
  },

  getFirstLeafMenu(menu) {
    let path = null
    path = menu.find(item => !item.children || !item.children.length || item.children.length === 0)
    if (path) {
      return path
    } else {
      path = menu.find(item => item.children && item.children.length && item.children.length > 0)
      return authorization.getFirstLeafMenu(path.children)
    }
  },

  // 标签栏信息初始化
  initTagsInfo(menu) {
    // 存储默认标签栏
    store.commit('SET_DEFAULT_TAG', menu)
    // 初始化标签栏数据
    store.commit('SET_TAGS', [{
      path: menu.path,
      label: menu.menuName
    }])
  },

  madeMenu(originMenu) {
    return originMenu.filter(omenu => {
      if (omenu.menuPath) {
        omenu.path = '/home' + omenu.menuPath
      } else {
        omenu.path = '/home' + omenu.path
      }
      omenu.menuName = omenu.meta.menuName
      omenu.iconClass = omenu.meta.icon
      if (omenu.children != null && omenu.children && omenu.children.length) {
        omenu.children = authorization.madeMenu(omenu.children)
      }
      return true
    })
  },

  // 更新用户路由
  setUserRouter (resRouter) {
    if (resRouter) {
      clientCache.sessionCache.setJSON('CLIENT_USER_ROUTER', resRouter)
    }
  },

  // 获取用户路由
  getUserRouter () {
    return clientCache.sessionCache.getJSON('CLIENT_USER_ROUTER')
  },

  // 更新用户菜单信息
  setUserMenu (userMenu) {
    clientCache.sessionCache.setJSON('CLIENT_USER_MENU', userMenu)
  },

  // 获取用户菜单信息
  getUserMenu () {
    return clientCache.sessionCache.getJSON('CLIENT_USER_MENU')
  },

  // 更新用户信息
  setUserInfo (userInfo) {
    if (userInfo) {
      clientCache.sessionCache.setJSON('USER_INFO', userInfo)
    }
  },

  // 获取用户信息
  getUserInfo () {
    return clientCache.sessionCache.getJSON('USER_INFO')
  },

  // 缓存用户可跳转的第一个菜单路由
  setUserFirstMenuPath(path) {
    return clientCache.sessionCache.set('USER_FIRST_MENU_PATH', path)
  },
  // 获取用户可跳转的第一个菜单路由
  getUserFirstMenuPath() {
    return clientCache.sessionCache.get('USER_FIRST_MENU_PATH')
  },
  // 缓存安全菜单路由
  setAutoJumpPath(path) {
    return clientCache.sessionCache.set('SAFE_PATH', path)
  },
  // 获取安全菜单路由
  getAutoJumpPath() {
    return clientCache.sessionCache.get('SAFE_PATH')
  }
}
export default authorization
