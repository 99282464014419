/**
 * sm2 加解密组件（用于缓存加解密）
 */
import { sm2 } from 'sm-crypto'
import systemConfig from '@/config/system-config'
import utilKit from '@/util'

const CACHE_PUBLIC = systemConfig.CACHE_PUBLIC
const CACHE_PRIVATE = systemConfig.CACHE_PRIVATE
// 1 - C1C3C2，0 - C1C2C3，默认为1
const cipherMode = 1

// 基础加密
const encrypto = function (str) {
  return '04' + sm2.doEncrypt(str, CACHE_PUBLIC, cipherMode)
}
// 基础解密
const decrypto = function (str) {
  return sm2.doDecrypt(str.substring(2), CACHE_PRIVATE, cipherMode)
}

// 完全加密(返回加密后的密文字符串)
const completeEncrypt = function (toEncrypt) {
  if (utilKit.checkDataType.getType(toEncrypt) !== 'string') {
    throw new Error('无法对\'' + utilKit.checkDataType.getType(toEncrypt) + '\'进行加密。Cannot encrypt \'' + utilKit.checkDataType.getType(toEncrypt) + '\'.')
  } else {
    return encrypto(toEncrypt)
  }
}
// 完全解密(返解密后的json字符串)
const completeDecrypt = function (toDecrypt) {
  if (utilKit.checkDataType.getType(toDecrypt) === 'string') {
    return decrypto(toDecrypt)
  } else {
    throw new Error('使用完全解密时，解密的入参必须是String类型。Expected input parameter is of type \'String\', but got a \'' + utilKit.checkDataType.getType(toDecrypt) + '\'.')
  }
}

// 标准加密(入参是对象，返回加密后的JSON对象)
const standardEncrypt = function (toEncrypt) {
  if (utilKit.checkDataType.getType(toEncrypt) === 'Object') {
    for (const key in toEncrypt) {
      toEncrypt[key] = encrypto(JSON.stringify(toEncrypt[key]))
    }
    return toEncrypt
  } else {
    throw new Error('使用标准加密时，入参必须是一个对象。In \'standardEncrypt (toEncrypt)\', the input parameter must be an object.')
  }
}
// 标准解密(入参是加密后的JSON对象，返回解密后的JSON对象)
const standardDecrypt = function (toDecrypt) {
  if (utilKit.checkDataType.getType(toDecrypt) === 'Object') {
    try {
      const toDecryptObj = {}
      for (const key in toDecrypt) {
        toDecryptObj[key] = JSON.parse(decrypto(toDecrypt[key]))
      }
      return toDecryptObj
    } catch (e) {
      console.log(e)
      throw new Error('数据损坏，解密失败！')
    }
  } else {
    throw new Error('使用标准解密时，解密的入参必须是String类型。Expected input parameter is of type \'String\', but got a \'' + utilKit.checkDataType.getType(toDecrypt) + '\'.')
  }
}

export default { completeEncrypt, completeDecrypt, standardEncrypt, standardDecrypt }
