import checkAuth from '@/util/directive/auth/check-auth'
import checkRole from '@/util/directive/auth/check-role'
import track from '@/util/directive/track/track'
import Vue from 'vue'

const installDirective = function(Vue) {
  Vue.directive('checkAuth', { inserted: checkAuth })
  Vue.directive('checkRole', { inserted: checkRole })
  // 埋点操作指令
  Vue.directive('track', { inserted: track })
}

if (window.Vue) {
  window.checkAuth = checkAuth
  window.checkRole = checkRole
  window.track = track
  Vue.use(installDirective); // eslint-disable-line
}

export default installDirective
