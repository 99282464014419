import checkDataType from './modules/check-data-type'
import reqFormatTool from '@/util/modules/request-params-format'
import md5Crypt from '@/util/modules/md5'
import duplicateReqInterceptor from '@/util/modules/duplicate-req-interceptor'
import unifiedResInterceptor from '@/util/modules/unified-res-interceptor'
import unifiedReqInterceptor from '@/util/modules/unified-req-interceptor'
import checkClient from '@/util/client/check-client'

const utilKit = { checkDataType, reqFormatTool, md5Crypt, duplicateReqInterceptor, unifiedResInterceptor, unifiedReqInterceptor, checkClient }

export default utilKit
