import { MessageBox, Notification } from 'element-ui'
import authorization from '@/store/utils/authorization'
import systemConfig from '@/config/system-config'
import router from '@/router'
import securityKit from '@/security'

/**
 * 统一响应拦截器
 */

// 重新登录提示控制标识
export let reLoginFlag = false

// 常规响应拦截处理
const doInterceptor = function (axiosResponse) {
  // 格式化响应体（仅包含响应数据data和响应头header）
  let tempAxiosResponse = { header: axiosResponse.headers, data: axiosResponse.data }

  // 二进制数据则直接返回
  if (axiosResponse.request.responseType === 'blob' || axiosResponse.request.responseType === 'arraybuffer') {
    return tempAxiosResponse
  }

  // 401或403
  if (tempAxiosResponse.data.status === 401 || tempAxiosResponse.data.status === 403) {
    return handleAuthFailRes()
  }

  // 解密
  if (systemConfig.RES_CRYPTO_FLAG) {
    tempAxiosResponse = securityKit.axiosCrypt.axiosResDecrypt(tempAxiosResponse)
  }

  // 100状态码(请求在业务上失败，直接拦截响应并显示后端异常消息)
  if (tempAxiosResponse.data.status === 100) {
    Notification.error({ title: '错误', message: tempAxiosResponse.data.msg, offset: 100 })
    return Promise.reject(new Error(tempAxiosResponse.data.msg))
  }

  return tempAxiosResponse
}

// 响应promise的rejected处理
const resErrorHandler = function (onRejected) {
  if (onRejected.message && onRejected.message === 'DUPLICATE_REQ') {
    return Promise.resolve({ data: { status: 100, msg: '请勿频繁重复操作！' } })
  } else {
    const error = JSON.parse(JSON.stringify(onRejected))
    console.log('响应异常：', onRejected, error.message)
    if (error.config) {
      console.log('异常来自于请求路径：' + error.config.baseURL + error.config.url)
    }
    return handleResErrorCode(error)
  }
}

function handleAuthFailRes() {
  if (!reLoginFlag) {
    reLoginFlag = true
    MessageBox.confirm('登录状态已过期，是否重新登录？', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      reLoginFlag = false
      // 登出
      authorization.handleLogout()
      const toPath = systemConfig.AUTH_FAIL_PATH || '/index'
      router.replace(toPath).then(() => {})
    }).catch(() => {
      reLoginFlag = false
    })
  }
  return Promise.reject(new Error('会话已过期，请重新登录。'))
}

function handleResErrorCode(error) {
  const code = error.status
  if (code === 401 || code === 403) {
    return handleAuthFailRes()
  } else {
    switch (code) {
      case 400:
        error.message = '错误请求'
        break
      case 404:
        error.message = '请求错误,未找到该资源'
        break
      case 405:
        error.message = '请求方法未允许'
        break
      case 408:
        error.message = '请求超时'
        break
      case 500:
        error.message = '服务器端出错'
        break
      case 501:
        error.message = '网络未实现'
        break
      case 502:
        error.message = '网络错误'
        break
      case 503:
        error.message = '服务不可用'
        break
      case 504:
        error.message = '网络超时'
        break
      case 505:
        error.message = '不支持该请求'
        break
      default:
        error.message = `连接错误${error.response.status}`
    }
    return Promise.resolve({ data: { status: 100, msg: error.message } })
  }
}

export default { doInterceptor, resErrorHandler }
