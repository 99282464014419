/**
 * 用于路由守卫中,路由跳转时切断未完成的请求
 */
const requestStore = {
  state: {
    requestList: []
  },
  mutations: {
    addRequestList (state, payload) {
      if (payload === 0) {
        // 强行中断时才向下执行
        state.requestList.forEach(item => {
          item('interrupt') // 中断请求标志
        })
        state.requestList = []
      } else {
        state.requestList.push(payload)
      }
    }
  },
  actions: {
    async removeRequestList (ctx) {
      ctx.commit('addRequestList', 0)
    }
  }
}
export default requestStore
