/**
 * 客户端信息缓存
 */

const clientStore = {
  state: {
    // 客户端状态时间戳
    clientStatus: '',
    // 当前访问的页面路由ID(用于接口请求时，携带在请求头，做审计日志使用)
    currentMenuId: ''
  },
  mutations: {
    SET_CLIENT_STATUS: (state, timestamp) => {
      state.clientStatus = timestamp
    },
    SET_CURRENT_MENU_ID: (state, menuId) => {
      state.currentMenuId = menuId
    }
  },
  getters: {
    getClientStatus(state) {
      return state.clientStatus
    },
    getCurrentMenuId(state) {
      return state.currentMenuId
    }
  }
}
export default clientStore
