import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import ToguideUI from 'toguide-ui'
import router from './router'
import store from './store'
import installDirective from '@/util/directive'

Vue.config.productionTip = false
// 取消点击弹窗之外区域弹窗关闭
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(ElementUI)
Vue.use(ToguideUI)
Vue.use(installDirective)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
