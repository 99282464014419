import authorization from '@/store/utils/authorization'

/**
 * v-checkAuth指令：校验权限
 * 校验当前用户是否具有相应权限，控制DOM元素显示或隐藏
 */
const checkAuth = function(el, binding) {
  const { value } = binding
  const allPermission = '*#*#*'
  let permissions = []
  if (authorization.getUserInfo() && authorization.getUserInfo().permissions) {
    permissions = authorization.getUserInfo().permissions
  }

  if (value && value instanceof Array && value.length > 0) {
    const permissionFlag = value
    const hasPermissions = permissions.some(permission => {
      return allPermission === permission || permissionFlag.includes(permission)
    })
    if (!hasPermissions) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error('请设置操作权限标签值')
  }
}
export default checkAuth
