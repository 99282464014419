/**
 * 浏览器刷新判断
 */
import store from '@/store'
import clientCache from '@/store/utils/client-cache'
const clientAction = function () {
  // 读取sessionStorage里的CLIENT_ST（客户端状态信息）缓存
  const clientStInSession = clientCache.sessionCache.get('CLIENT_ST')
  // 读取vuex中的客户端状态
  const clientStInStore = store.getters.getClientStatus

  // 判断是否属于刷新
  if (!clientStInSession) {
    // 新开客户端
    const timeStamp = (new Date()).getTime()
    clientCache.sessionCache.set('CLIENT_ST', timeStamp.toString())
    return 'new'
  } else if (!clientStInStore) {
    // 页面刷新
    const timeStamp = (new Date()).getTime()
    store.commit('SET_CLIENT_STATUS', timeStamp)
    return 'refresh'
  } else {
    // 页面切换
    return 'shift'
  }
}

export default { clientAction }
