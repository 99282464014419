/**
 * 系统配置文件
 */
export default {
  /**
   * 请求配置
   */
  // 请求超时时间
  REQUEST_TIMEOUT: 30 * 1000,
  // 是否开启重复请求拦截
  RESUBMIT_INTERCEPT: true,
  // 重复请求的时间校验范围（默认5秒，小于此间隔时间则视为重复请求）
  RESUBMIT_INTERCEPT_INTERVAL: 1 * 1000,
  // 需要执行重复拦截的请求方法类型
  RESUBMIT_INTERCEPT_METHODS: ['post', 'put', 'get'],

  /**
   * 布局配置
   */
  // 是否显示标签栏
  TAGS_SHOW: true,
  // 是否固定头部
  FIXED_HEADER: true,

  /**
   * 路由配置
   */
  // 免登录白名单（路由名称）
  ROUTER_WHITE_LIST: ['Login', 'Logout', 'Index', 'notificationDetail'],
  // 登录成功后的跳转路径(此配置优先级高于login-store.js中的firstMenuPath)
  // 若不配置，则登录成功后默认跳转store/modules/login-store.js中的firstMenuPath
  FIRST_PAGE_PATH: '/index',

  /**
   * 系统配置
   */
  // 系统标识符(唯一标识本系统)
  SYS_CODE: '',
  // 本系统在认证系统的回调地址
  CLIENT_PATH: 'https://top.toguide.cn/login',
  // 鉴权失败跳转路径。（拦截到403或401响应时，跳转页面的路由。）默认跳转至'/index'
  AUTH_FAIL_PATH: '/login',

  /**
   * 日志相关
   */
  // 是否启用数据埋点功能（包括埋点指令和页面动作埋点）
  EVENT_TRACKING: false,
  // 是否启用审计日志请求头（开启审计日志记录功能）
  LOG_ENABLE: true,

  /**
   * TIM集成配置
   */
  // 是否启用ISC登录
  TIM_ENABLE: false,

  /**
   * 安全配置
   */
  // 统一请求拦截器是否执行请求加密
  REQ_CRYPTO_FLAG: false,
  // 统一响应拦截器是否执行响应解密
  RES_CRYPTO_FLAG: false,
  // 请求加密
  REQ_PUBLIC: '043CAF0036B7559D4993E2C25EF72C6A84623D7B287E5714E41433CE2840C682A0AAB7545B1A69530DC1C8DB884BD245ED999C2BE448657D1FA78E044E697541BD',
  // 响应解密
  RES_PRIVATE: '16EC9EFAE5F87DB48E21024DA16C732D046BF5F34C80D1C38E988F3BE2DAD710',
  // 防会话重放攻击固定盐值
  REPLAY_ATTACKS_SALT: '04NO39Z15O85C51O2DI1',

  /**
   * 缓存配置
   */
  // 本地缓存和会话缓存数据是否加密
  CACHE_CRYPTO: false,
  // 缓存加密
  CACHE_PUBLIC: '043CAF0036B7559D4993E2C25EF72C6A84623D7B287E5714E41433CE2840C682A0AAB7545B1A69530DC1C8DB884BD245ED999C2BE448657D1FA78E044E697541BD',
  // 缓存解密
  CACHE_PRIVATE: '1141441A833AE2BFE02EA1187E3E6D0509A5EB643394C2434D072AE247003EBB'
}
